'use client';

import React, { JSX } from 'react';
import { AlbatrosLogoDesktopFull } from '@/Theme/SVG/AlbatrosLogos';
import CustomSpinner from '@/components/CustomSpinner/CustomSpinner';
import classNames from 'classnames';

const SuspenseFallbackComponent = ({
  logoOnly,
  fitToContainer,
  spinnerOnly,
  fixedPosition
}: {
  logoOnly?: boolean;
  spinnerOnly?: boolean;
  fitToContainer?: boolean;
  fixedPosition?: boolean;
}): JSX.Element => {
  return (
    <div
      className={classNames('suspense-fallback', {
        'suspense-fallback--fit': fitToContainer,
        'suspense-fallback--fixed': fixedPosition
      })}
    >
      <div className="suspense-fallback__content">
        {!spinnerOnly && <AlbatrosLogoDesktopFull logoOnly={logoOnly} />}
        <CustomSpinner
          className={classNames('suspense-fallback__spinner', {
            'suspense-fallback__spinner--only': spinnerOnly
          })}
        />
      </div>
    </div>
  );
};

export default SuspenseFallbackComponent;
