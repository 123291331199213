import React, { CSSProperties, FC } from 'react';
import classNames from 'classnames';
import { AlbatrosOnly } from '@/Theme/SVG/AlbatrosLogos';

const CustomSpinner: FC<{ className?: string; style?: CSSProperties }> = ({
  className,
  style
}): JSX.Element => {
  return (
    <span className={classNames('custom-spinner__container', className || '')}>
      <span style={style} className="custom-spinner" />
      <AlbatrosOnly />
    </span>
  );
};

export default CustomSpinner;
